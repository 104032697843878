import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/layouts/top-menu/Main'),
        children: [
            {
                path: '/',
                name: 'Main',
                component: () => import('@/views/public/Main'),
            },
            {
                path: '/NewsForm',
                name: 'NewsForm',
                component: () => import('@/views/public/sections/NewsForm.vue'),
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('@/views/public/sections/AboutDetails.vue'),
            },
            {
                path: '/validation_contract/:id',
                name: 'CheckPolice',
                component: () => import('@/views/public/CheckPolice.vue'),
            },
        ],
    },
    {
        path: '/404',
        name: 'ErrorPage',
        component: () => import('@/views/error-page/404.vue'),
    },
    {
        path: '/403',
        name: 'ErrorPage403',
        component: () => import('@/views/error-page/403.vue'),
    },


    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/error-page/404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: -400,
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return {left: 0, top: 0};
        }
    },
});

export default router;
