import axios from 'axios'
import {getItem} from '@/helpers/persistanceStorage'
import mixins from "@/helpers/mixins";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use(
    config => {
        mixins().checkJwtExp()
        const token = getItem('user')
        if (token) {
            const jwtToken = token ? `Bearer ${token.jwtToken}` : ''
            config.headers.Authorization = jwtToken
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
export default api
