import Modal from '@/app-components/Modal'
import AppFrame from '@/app-components/AppFrame'
import Spinner from '@/app-components/UIComponents/Spinner'

export default [
    Spinner,
    {
        name: 'Modal',
        component: Modal
    },
    {
        name: 'AppFrame',
        component: AppFrame
    }
]

