import $api from '@/http'

async function policeCheck(params) {
    return await $api.get('v1/contract/public', {
        params: {
            page: params.page,
            size: params.size,
            active: params.active,
            contract_number: params.contractNumber,
            blank_series: params.blankSeries,
            blank_number: params.blankNumber,
            owner_pin_tin: params.ownerPinTin,
            car_vin: params.carVin,
            car_registration_number: params.carNumber
        }
    })
}

async function getNewsList() {
    return await $api.get('/mainPublic/news/list')
}

async function getNewsListId(newsId) {
    return await $api.get('/mainPublic/news/getById?id=' + newsId)
}

async function getPartnerPublicAll() {
    return await $api.get('/v1/organization/public')
}

async function getPravovyeActyList() {
    return await $api.get('/mainPublic/pravovyeActy/list')
}

async function checkPolicyByQR(id) {
    return await $api.get(`/v1/contract/${id}/public`, {
    })
}

export default {
    policeCheck,
    getNewsList,
    getNewsListId,
    getPartnerPublicAll,
    getPravovyeActyList,
    checkPolicyByQR

}

