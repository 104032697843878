const state = () => {

    return {
        menu: [
            {
                pageName: 'Main',
                title: 'Главная',
                titleKG: 'Башкы',
                props: {targetBlock: 'hero'}
            },
            {
                pageName: 'Main',
                title: 'О нас',
                titleKG: 'Биз жөнүндө',
                props: {targetBlock: 'about'}
            },
            {
                pageName: 'Main',
                title: 'Новости',
                titleKG: 'Жаңылыктар',
                props: {targetBlock: 'news'}
            },
            {
                pageName: 'Main',
                title: 'Проверка полиса',
                titleKG: 'Полисти текшерүү',
                props: {targetBlock: 'checkPolicyBlock'}
            },
            // {
            //     pageName: 'Main',
            //     title: 'НПА',
            //     titleKG: 'ЧУА',
            //     props: {targetBlock: 'legalActsBlock'}
            // },
            // {
            //     pageName: 'Main',
            //     title: 'Калькулятор',
            //     titleKG: 'Жаңылыктар',
            //     props: {targetBlock: 'calculate'}
            // },
            {
                pageName: 'Main',
                title: 'Члены ОЮЛ "КАС"',
                titleKG: 'Мүчөлөрү ЮЖБ "ККА"',
                props: {targetBlock: 'partnersBlock'}
            },
            {
                pageName: 'Main',
                title: 'Калькулятор ОСАГО',
                titleKG: 'ОСАГО калкулятору',
                props: {targetBlock: 'osagoCalculation'}
            }
        ]
    }
}

// getters
const getters = {
    menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
