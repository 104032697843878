import $api from '@/http'

async function singIn(payload) {
    try {
        const response = await $api.post('/authenticate', payload)
        return {user: response.data, status: response.status}
    } catch (e) {
        return {error: e.response.data, status: e.response.status}
    }
}

async function verify(payload) {
    return await $api.post('/verify', {}, {
        params: payload
    })
}

export default {
    singIn,
     verify,
}
