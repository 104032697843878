const state = () => {
    return {
        menu: [
            {
                icon: 'DatabaseIcon',
                pageName: 'side-menu-dashboard',
                title: 'Dictionaries',
                subMenu: [
                    {
                        pageName: 'Countries',
                        title: 'Countries',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Oblasts',
                        title: 'Areas',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Rayons',
                        title: 'Districts',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'TypeTransport',
                        title: 'TypeTransport',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'VidDokumenta',
                        title: 'VidDocument',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'TseliIspolzovaniya',
                        title: 'PurposeUsingVehicle',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'TypeOrganisation',
                        title: 'TypeOrganization',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'TypeFilial',
                        title: 'TypeFilial',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'BlankStatus',
                        title: 'BlankStatus',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'MetodOplaty',
                        title: 'MetodOplaty',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'StatusOplaty',
                        title: 'statusOplaty',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'StatusStrahSluchay',
                        title: 'statusStrahSluchay',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'statusNeregPretenz',
                        title: 'statusNeregPretenz',
                        permission: ['DICT_READ'],
                    },
                    // {
                    //     pageName: 'Partner',
                    //     title: 'partner',
                    //     permission: ['DICT_READ'],
                    // },

                    // {
                    //     pageName: 'TypeInvalid',
                    //     title: 'typeInvalid',
                    //     permission: ['DICT_READ'],
                    // },
                    {
                        pageName: 'News',
                        title: 'News',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'pravovyeActy',
                        title: 'pravovyeActy',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Marka',
                        title: 'marka',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Model',
                        title: 'model',
                        permission: ['DICT_READ'],
                    }
                ]
            },
            {
                icon: 'ActivityIcon',
                pageName: 'side-menu-dashboard',
                title: 'ratio',
                subMenu: [
                    {
                        pageName: 'Calculate',
                        title: 'calculate',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Day',
                        title: 'day',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'Staj',
                        title: 'staj',
                        permission: ['DICT_READ'],
                    },
                    {
                        pageName: 'BonusMalus',
                        title: 'bonusMalus',
                        permission: ['DICT_READ'],
                    },
                ]
            },
            {
                icon: 'UsersIcon',
                pageName: 'side-menu-dashboard',
                title: 'Agents',
                subMenu: [
                    {
                        pageName: 'AgentsReestr',
                        title: 'Agents',
                        permission: ['AGENT_READ'],
                    },
                    {
                        pageName: 'AgentsReestrUvolennye',
                        title: 'AgentsUvolennye',
                        permission: ['AGENT_BL_READ'],
                    },
                    {
                        pageName: 'Blacklist',
                        title: 'Blacklist',
                        permission: ['AGENT_BL_READ'],
                    },
                ]
            },
            {
                icon: 'UsersIcon',
                pageName: 'side-menu-dashboard',
                title: 'AgentUsers',
                subMenu: [
                    {
                        pageName: 'Agents',
                        title: 'AgentUsers',
                        permission: ['USER_READ'],
                    },
                ]
            },
            {
                icon: 'FileIcon',
                pageName: 'side-menu-dashboard',
                title: 'ApplicationsPolicies',
                subMenu: [
                    {
                        pageName: 'ClientWhiteList',
                        title: 'ClientReestr',
                        permission: ['CLIENT_READ'],
                    },
                    {
                        pageName: 'ClientBlackList',
                        title: 'ClientBlackReestr',
                        permission: ['CLIENT_BL_READ'],
                    },
                    {
                        pageName: 'ListApplications',
                        title: 'listApplications',
                        permission: ['APP_READ'],
                    },
                    {
                        pageName: 'ListInsurances',
                        title: 'listInsurances',
                        permission: ['POLIS_READ'],
                    },
                    {
                        pageName: 'ListInsurancesNeObrabotan',
                        title: 'listInsurancesNeObrabotan',
                        permission: ['POLIS_READ'],
                    },
                    {
                        pageName: 'registerArchivedPolicies',
                        title: 'registerArchivedPolicies',
                        permission: ['POLIS_READ'],
                    }
                ]
            },
            {
                icon: 'FileIcon',
                pageName: 'side-menu-dashboard',
                title: 'strahSluchay',
                subMenu: [
                    {
                        pageName: 'ListInsuranceCases',
                        title: 'strahSluchayList',
                        permission: ['IN_CASE_READ'],
                    },
                    {
                        pageName: 'ListNeregPretenziya',
                        title: 'neregPretenziyaList',
                        permission: ['PRETENZIA_READ'],
                    }
                ]
            },
            {
                icon: 'UsersIcon',
                pageName: 'PartnerPublic',
                title: 'partner',
                permission: ['DICT_READ'],
            },
            {
                icon: 'MenuIcon',
                pageName: 'OrganisationList',
                title: 'Organisations',
                permission: ['ORG_READ'],
            },
            {
                icon: 'MenuIcon',
                pageName: 'Filials',
                title: 'Filials',
                permission: ['FILIAL_READ'],
            },
            {
                icon: 'BookIcon',
                pageName: 'side-menu-dashboard',
                title: 'BlankReestr',
                subMenu: [
                    {
                        pageName: 'BlankGroups',
                        title: 'BlankGroups',
                        permission: ['BLANK_GROUP_READ'],
                    },
                    {
                        pageName: 'BlankIssued',
                        title: 'BlankIssued',
                        permission: ['ISSUED_BLANK_READ'],
                    },
                    {
                        pageName: 'ListNeispBlanks',
                        title: 'ListNeispBlanks',
                        permission: ['NEISP_BLANK_READ'],
                    },
                    {
                        pageName: 'BlankInvalid',
                        title: 'BlankInvalid',
                        permission: ['INVALID_BLANK_READ'],
                    },
                    {
                        pageName: 'BlankLost',
                        title: 'BlankLost',
                        permission: ['LOST_BLANK_READ'],
                    },
                    {
                        pageName: 'BlankDamaged',
                        title: 'BlankDamaged',
                        permission: ['SPOILED_BLANK_READ'],
                    },
                ]
            },
            {
                icon: 'FileTextIcon',
                pageName: 'Reports',
                title: 'reports',
                permission: ['REPORT_READ'],
            },
            {
                icon: 'LockIcon',
                pageName: 'side-menu-dashboard',
                title: 'Administration',
                subMenu: [
                    {
                        pageName: 'UserRole',
                        title: 'UserRoles',
                        permission: ['SUPER_ADMIN'],
                    },
                    {
                        pageName: 'AccessControl',
                        title: 'AccessControl',
                        permission: ['SUPER_ADMIN'],
                    },
                    // {
                    //     pageName: 'Users',
                    //     title: 'Users',
                    //     permission: ['SUPER_ADMIN'],
                    // },
                    {
                        pageName: 'Logs',
                        title: 'logs',
                        permission: ['LOG_READ'],
                    },
                    {
                        pageName: 'LoginLogs',
                        title: 'loginLogs',
                        permission: ['SUPER_ADMIN'],
                    },
                ]
            }
        ]
    }
}

// getters
const getters = {
    menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
